<template>
  <v-container>
    
    <v-row>
      <v-col cols="4">
        <h4 class="mb-3">Permisos:</h4>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Id Cliente
                </th>
                <th class="text-left">
                  Cliente
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in user.clients"
                :key="item.clientId"
              >
                <td>{{ item.clientId }}</td>
                <td>{{ item.clientDesc }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="8">
        <v-img
            alt="PromoKIN"
            contain
            src="../assets/ulinkTel.png"
            cover
            max-height="350"
            class="mt-13"
          />
          
      </v-col>
      <v-col cols="12" class="text-right">
        <h4 class="mr-12">U.link - Versión 1.3</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapState(['user'])
  },
}
</script>
